import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
} from '@backstage/core-plugin-api';
import {
  ScmAuth,
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
} from '@backstage/integration-react';
import React from 'react';

import type { ApiEntity } from '@backstage/catalog-model';
import { apiDocsConfigRef, defaultDefinitionWidgets } from '@backstage/plugin-api-docs';
import { techRadarApiRef } from '@backstage/plugin-tech-radar';
import { RedocStandalone } from 'redoc';
import yaml from 'yaml';
import { TechRadarClient } from './lib/TechRadarClient';

export function ApiSpec({ definition }: { definition: any }) {
  return (
    <div style={{ backgroundColor: 'white' }}>
      <RedocStandalone
        spec={definition}
        options={{
          showExtensions: true,
          nativeScrollbars: true,
          theme: {},
        }}
      />
    </div>
  );
}

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory(techRadarApiRef, new TechRadarClient()),
  createApiFactory({
    api: apiDocsConfigRef,
    deps: {},
    factory: () => {
      // load the default widgets
      const definitionWidgets = defaultDefinitionWidgets();
      return {
        getApiDefinitionWidget: (apiEntity: ApiEntity) => {
          if (apiEntity.spec.type === 'openapi') {
            return {
              rawLanguage: 'yaml',
              title: 'OpenAPI',
              type: 'openapi',
              component: (definition: string) => <ApiSpec definition={yaml.parse(definition)} />,
            };
          }

          // fallback to the defaults
          return definitionWidgets.find(d => d.type === apiEntity.spec.type);
        },
      };
    },
  }),

];
