/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 28,
  },
  path: {
    fill: '#F44336',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 148"
    >
      <path
        className={classes.path}
        d="M134.78 23.683C138.08 14.9608 133.483 5.0599 124.643 1.99533C121.815 1.05239 118.868 0.69878 115.921 1.28812L11.0188 19.5576L1 47.1387L34.3567 41.2453L24.3379 68.8264C21.0376 77.5486 25.6344 87.4495 34.4745 90.5141C37.3033 91.457 40.25 91.8106 43.1967 91.2213L147.981 72.8339L158 45.2528L124.643 51.1462L134.78 23.683ZM91.2868 57.0396L57.9302 62.933L67.949 35.352L101.306 29.4586L91.2868 57.0396Z"
      />
    </svg>
  );
};

export default LogoIcon;
