import React from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';

import {
  HomePageCompanyLogo,
  HomePageStarredEntities,
} from '@backstage/plugin-home';
import LogoFull from '../Root/LogoFull';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { HomePageSearchBar } from '@backstage/plugin-search';
import {
  Content,
  InfoCard,
  ItemCardGrid,
  ItemCardHeader,
  Page,
} from '@backstage/core-components';

const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    maxWidth: '60vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    padding: '8px 0',
    borderRadius: '50px',
    margin: '30px auto',
  },
}));

const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
  },
}));

export const HomePage = () => {
  const classes = useStyles();
  const { container } = useLogoStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <HomePageCompanyLogo className={container} logo={<LogoFull />} />
          </Grid>
          <Grid container justifyContent="center" spacing={6}>
            <Typography variant="h4">
              버즈빌 Backstage에 오신 것을 환영합니다.
            </Typography>
          </Grid>
          <Grid container item xs={12} alignItems="center" direction="row">
            <HomePageSearchBar
              classes={{ root: classes.searchBar }}
              placeholder="Search"
            />
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12} md={6}>
              <InfoCard title="Recommended Items">
                <ItemCardGrid>
                  <Card key="docs">
                    <CardMedia>
                      <ItemCardHeader title="Docs" />
                    </CardMedia>
                    <CardContent>
                      버즈빌의 기술문서들을 확인해 보세요.
                    </CardContent>
                    <CardActions>
                      <Button color="primary" href="/docs">
                        Go to Docs
                      </Button>
                    </CardActions>
                  </Card>
                </ItemCardGrid>
              </InfoCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <HomePageStarredEntities />
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
