import {
  TechRadarLoaderResponse,
  TechRadarApi,
} from '@backstage/plugin-tech-radar';

import { load } from 'js-yaml';

export class TechRadarClient implements TechRadarApi {
  async load(_id: string | undefined): Promise<TechRadarLoaderResponse> {
    // if needed id prop can be used to fetch the correct data

    const data: any = await fetch('/tech-radar.yaml')
      .then(res => res.text())
      .then(yaml => load(yaml));

    // For example, this converts the timeline dates into date objects
    return {
      ...data,
      entries: data.entries.map((entry: any) => ({
        ...entry,
        timeline: entry.timeline.map((timeline: any) => ({
          ...timeline,
          date: new Date(timeline.date),
        })),
      })),
    };
  }
}
